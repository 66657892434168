import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

type StudentInformationProps = ReportStore.ReportState & typeof ReportStore.actionCreators & RouteComponentProps<{}>;

function StudentInformation(props: StudentInformationProps) {
  let dt: DataTable | null | undefined;
  const [studentInformation, setStudentInformation] = useState(props.studentInformation);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState();
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);


  useEffect(() => {
    props.getStudentInformation();
    props.getCohorts();
  }, []);

  useEffect(() => {
    setStudentInformation(props.studentInformation);
  }, [props.studentInformation]);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  function onCohortChange(event: any) {
    if (dt) {
      dt.filter(event.value, 'cohortId', 'equals');
      setSelectedCohorts(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }

  function avenueScholarTemplate(rowData: any) {
    let value = rowData.isAvenueScholar as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }

  function careerAcademyTemplate(rowData: any) {
    let value = rowData.isMCCCareerAcademy as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }

  function interestedInMCCTemplate(rowData: any) {
    let value = rowData.isInterestedInMCC as boolean;

    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }



  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        value={globalFilter ?? ''}
        onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
        placeholder="Global Search"
        size={50}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />

    </div>
  );

  return (
    <React.Fragment>
      <h1>Student Information</h1>

      <DataTable
        ref={(el) => (dt = el)}
        value={studentInformation}
        resizableColumns columnResizeMode="expand"
        //onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
        style={{ width: '100%' }}
      >
        <Column field="firstName" header="First Name" sortable={true} style={{ width: '70px' }} />
        <Column field="lastName" header="Last Name" sortable={true} style={{ width: '100px' }} />
        <Column field="email" header="Email" sortable={true} style={{ width: '250px' }} />
        <Column field="phone" header="Phone" sortable={true} style={{ width: '120px' }} />
        <Column field="schoolDistrict" header="School District" sortable={true} style={{ width: '80px' }} />
        <Column field="highSchool" header="High School" sortable={true} style={{ width: '70px' }} />
        <Column field="gender" header="Gender" sortable={true} style={{ width: '70px' }} />
        <Column field="race" header="Race" sortable={true} style={{ width: '90px' }} />
        {/*<Column field="isAvenueScholar" header="Is Avenue Scholar" sortable={true} style={{ width: '70px' }} body={avenueScholarTemplate} />*/}
        {/* <Column field="isMCCCareerAcademy" header="Is MCC Career Academy" sortable={true} style={{width: '80px'}} body={careerAcademyTemplate} /> */}
        <Column field="isInterestedInMCC" header="Is Interested In MCC" sortable={true} style={{ width: '100px' }} body={interestedInMCCTemplate} />
        <Column field="graduationYear" header="Grad Year" sortable={true} style={{ width: '60px' }} />
        <Column field="freeOrReducedLunch" header="Free Or Reduced Lunch" sortable={true} style={{ width: '80px' }} />
        <Column field="cohortId" style={{ display: 'none' }} header="Cohort" />
      </DataTable>
    </React.Fragment>
  );

  function handleRowClick(e: any) {
    //props.history.push(`/reports/${e.data.route}`);
  }
}

export default connect((state: ApplicationState) => state.report, ReportStore.actionCreators)(StudentInformation as any);
