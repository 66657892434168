import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report";
import * as UserStore from "../../../store/user";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";

type StudentApplicationsProps = ReportStore.ReportState & typeof ReportStore.actionCreators & RouteComponentProps<{}>;


function StudentApplications(props: StudentApplicationsProps) {
  let dt: DataTable | null | undefined;
  const [studentsInRoles, setStudentsInRoles] = useState(props.studentsInRoles);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedCohorts, setSelectedCohorts] = useState();
  const [cohortList, setCohortList] = useState([{ label: "Test", value: "" }]);


  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const school = query.get('HighSchool')
    setGlobalFilter(school ?? "");
    props.getStudentApplications();
    props.getCohorts();
  }, []);

  useEffect(() => {
    setStudentsInRoles(props.studentsInRoles);
  }, [props.studentsInRoles]);

  useEffect(() => {
    const cohorts = props.cohortList.map((cohort) => {
      return { label: cohort.name, value: cohort.id.toString() };
    });
    setCohortList(cohorts);
  }, [props.cohortList]);

  function onCohortChange(event: any) {
    if (dt) {
      dt.filter(event.value, 'cohortId', 'equals');
      setSelectedCohorts(event.value);
    }
  }

  function onExport() {
    if (dt) dt.exportCSV({ selectionOnly: false });
  }

  const header = (
    <div style={{ textAlign: "left" }}>
      <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }}></i>
      <InputText
        type="search"
        value={globalFilter ?? ''}
        onInput={(e) => setGlobalFilter((e.target as HTMLTextAreaElement).value)}
        placeholder="Global Search"
        size={50}
      />
      <Button className="float-right" type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={onExport}></Button>
      <Dropdown style={{ width: '200px' }} placeholder="Select a Cohort" value={selectedCohorts} options={cohortList} onChange={onCohortChange} showClear />

    </div>
  );

  return (
    <React.Fragment>
      <h1>Student Applications</h1>

      <DataTable
        ref={(el) => (dt = el)}
        value={studentsInRoles}
        //onRowClick={($event) => handleRowClick($event)}
        selectionMode="single"
        header={header}
        globalFilter={globalFilter}
      >
        <Column field="student.firstName" header="First Name" sortable={true} />
        <Column field="student.lastName" header="Last Name" sortable={true} />
        <Column field="student.email" header="Email" sortable={true} />
        <Column field="student.phone" header="Phone" sortable={true} />
        <Column field="student.schoolDistrict" header="School District" sortable={true} />
        <Column field="student.highSchool" header="High School" sortable={true} />
        <Column field="student.gender" header="Gender" sortable={true} />
        <Column field="student.race" header="Race" sortable={true} />
        {/*<Column field="student.isAvenueScholar" header="Is Ave Sch" body={isAveTemplate} sortable={true} />*/}
        {/* <Column field="student.isMCCCareerAcademy" header="Is MCC" body={isMCCTemplate} sortable={true} /> */}
        <Column field="application.internship.employer.employerName" header="Employer" sortable={true} />
        <Column field="application.internship.internshipDomain" header="Domain" sortable={true} />
        <Column field="application.internship.internshipTitle" header="Title" sortable={true} />
        <Column field="application.internship.payPerHour" header="Pay" body={payTemplate} sortable={true} />
        <Column field="application.applicationStatus" header="Status" body={statusTemplate} sortable={true} />
        <Column field="application.declineReason" header="Decline Reason" sortable={true} />
        <Column field="cohortId" style={{ display: 'none' }} header="Cohort" />
      </DataTable>
    </React.Fragment>
  );

  function moneyTemplate(rowData: any, column: any) {
    var amount = parseFloat(rowData.application.internship.payPerHour);

    return <div>{amount.toLocaleString("en-US", { style: "currency", currency: "USD" })}</div>;
  }

  function isAveTemplate(rowData: any) {
    let value = rowData.student.isAvenueScholar as boolean;
    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }

  function isMCCTemplate(rowData: any) {
    let value = rowData.student.isMCCCareerAcademy as boolean;
    if (value) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  }

  function statusTemplate(rowData: any) {
    switch (rowData.application.applicationStatus) {
      case 0:
        return 'Applied';
        break;
      case 1:
        return 'Under Review';
        break;
      case 2:
        return 'Selected For Interview';
        break;
      case 3:
        return 'Declined';
        break;
      case 4:
        return 'Offer Out';
        break;
      case 5:
        return 'Offer Accepted';
        break;
      case 6:
        return 'Offer Declined';
        break;
      case 10:
        return 'Incomplete Submission';
        break;
    }
  }

  function payTemplate(rowData: any, column: any) {
    var minAmount = parseFloat(rowData.application.internship.payPerHour);
    var maxAmount = parseFloat(rowData.application.internship.payPerHourMax);

    return <div>
      {minAmount ? minAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ' n/a'}-{maxAmount ? maxAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ' n/a'}
    </div>;
  }


  function handleRowClick(e: any) {
    //props.history.push(`/reports/${e.data.route}`);
  }
};

export default connect((state: ApplicationState) => state.report, ReportStore.actionCreators)(StudentApplications as any);
